import React from "react";
import Layout from "../layout/default";
import Seo from "../components/seo";
import Latest from '../components/Latest';

const LatestPage = ({ location, pageContext }) => (
    <Layout location={location} dynamicDefaultPaths={pageContext.dynamicDefaultPaths}>
        <Seo title="Latest" />
        <Latest />
    </Layout>
)

export default LatestPage;
