import React from 'react';
import axios from 'axios';
import Title from '../Title';
import * as s from './s.module.css'
import CircularLoader from '../CircularLoader';

const blogRssUrl = 'https://api.rss2json.com/v1/api.json?rss_url=https://virtuepoker.ghost.io/rss/';

// const mediumUrl = 'https://virtuepoker.ghost.io/';


interface State {
  loading: boolean,
  posts: RssPost[],
  failed: boolean,
}

interface RssPost {
  title: string,
  content: string,
  pubDate: string,
  link: string,
}

class Latest extends React.Component<{}, State> {
  constructor(props) {
    super(props);

    this.state = {
      failed: false,
      posts: [],
      loading: false,
    }
  }

  componentDidMount() {
    this.getMediumPosts();
  }

  getMediumPosts = async () => {
    try {
      this.setState({ loading: true, failed: false });
      const { data } = await axios.get(blogRssUrl);
      const { items } = data;
      this.setState({ loading: false, posts: items });
    } catch (error) {
      this.setState({ loading: false, failed: true });
    }
  }

  nodeToText = node => {
    const tag = document.createElement('div');
    tag.innerHTML = node;
    const text = tag.innerText;
    return text;
  }

  render() {
    const { loading, posts, failed } = this.state;
    return (
      <section className={s.sectionContainer}>
        <div className={s.sectionContent}>
          <Title>Latest</Title>
          <div className={s.postsContainer}>
            {(() => {
              if (loading) {
                return <CircularLoader size={60} />;
              } else if (failed) {
                return <p className={s.error}>Ooopps..Posts not found!</p>;
              } else {
                return (
                  posts.map((post, idx) => (
                    <a key={idx} className={s.postItem} href={post.link} target="_blank">
                      <p className={s.postTitle}>{post.title}</p>
                      <p className={s.postDate}>{post.pubDate}</p>
                      <p className={s.postContent}>{`${this.nodeToText(post.content).slice(0, 300)}...`}</p>
                      <p className={s.readMore}>Read more...</p>
                    </a>
                  ))
                )
              }
            })()}
          </div>
        </div>
      </section>
    )
  }
}

export default Latest;
